<template>
  <div>
    <el-form :inline="true" @keyup.enter.native="getQuestionList">
      <el-form-item>
        <el-input
            v-model="searchForm.keyword"
            placeholder="名称"
            clearable
        >
        </el-input>
      </el-form-item>
      <el-form-item label="分类" prop="category" label-width="100px">
        <el-select v-model="searchForm.category" placeholder="请选择分类">
          <el-option value="">不限制</el-option>
          <template v-for="item in permTreeData">
            <el-option :label="item.name" :value="item.id"></el-option>
            <template v-for="child in item.children">
              <el-option :label="child.name" :value="child.id">
                <span>{{ "- " + child.name }}</span>
              </el-option>
            </template>
          </template>
        </el-select>
        <!-- <el-input v-model="editForm.category" autocomplete="off"></el-input> -->
      </el-form-item>
      <el-form-item>
        <el-button @click="getQuestionList">搜索</el-button>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="dialogVisible = true">新增</el-button>
      </el-form-item>
      <el-form-item>
        <el-popconfirm title="这是确定批量删除吗？" @confirm="delHandle(null)">
          <el-button type="danger" slot="reference" :disabled="delBtlStatu">批量删除</el-button>
        </el-popconfirm>
      </el-form-item>
    </el-form>

    <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        border
        stripe
        @selection-change="handleSelectionChange">

      <el-table-column
          type="selection"
          width="55">
      </el-table-column>

      <el-table-column
          prop="question"
          label="问题">
      </el-table-column>
      <el-table-column
          prop="categoryName"
          label="分类"
          width="120">
      </el-table-column>
      <el-table-column
          prop="icon"
          label="操作"
          width="320">

        <template slot-scope="scope">
          <el-button type="text" @click="permHandle(scope.row.id)">详情</el-button>
          <el-divider direction="vertical"></el-divider>

          <el-button type="text" @click="editHandle(scope.row.id)">编辑</el-button>
          <el-divider direction="vertical"></el-divider>

          <template>
            <el-popconfirm title="这是一段内容确定删除吗？" @confirm="delHandle(scope.row.id)">
              <el-button type="text" slot="reference">删除</el-button>
            </el-popconfirm>
          </template>

        </template>
      </el-table-column>

    </el-table>


    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        layout="total, sizes, prev, pager, next, jumper"
        :page-sizes="[10, 20, 50, 100]"
        :current-page="current"
        :page-size="size"
        :total="total">
    </el-pagination>


    <!--新增对话框-->
    <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="1300px"
        :before-close="handleClose">

      <el-form :model="editForm" :rules="editFormRules" ref="editForm" label-width="100px" class="demo-editForm">

        <el-form-item label="标题" prop="question" label-width="100px">
          <el-input v-model="editForm.question" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="分类" prop="category" label-width="100px">
          <el-select v-model="editForm.category" placeholder="请选择上级菜单">
            <template v-for="item in permTreeData">
              <el-option :label="item.name" :value="item.id"></el-option>
              <template v-for="child in item.children">
                <el-option :label="child.name" :value="child.id">
                  <span>{{ "- " + child.name }}</span>
                </el-option>
              </template>
            </template>
          </el-select>
          <!-- <el-input v-model="editForm.category" autocomplete="off"></el-input> -->
        </el-form-item>

        <el-form-item label="答案" prop="answer" label-width="100px">
          <quill-editor
              v-model="editForm.answer"
              ref="myQuillEditor"
              :options="editorOption"
              @blur="onEditorBlur($event)"
              @focus="onEditorFocus($event)"
              @change="onEditorChange($event)">
          </quill-editor>
          <!--  <el-input v-model="editForm.answer" autocomplete="off"></el-input>-->
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="submitForm('editForm')" v-if="showFrom===0">立即创建</el-button>
          <el-button @click="resetForm('editForm')" v-if="showFrom===0">重置</el-button>
          <el-button @click="closeForm('editForm')" v-if="showFrom===1">确定</el-button>
        </el-form-item>
      </el-form>

    </el-dialog>

  </div>
</template>

<script>
export default {
  name: "question",
  data() {
    return {
      searchForm: {},
      delBtlStatu: true,

      total: 0,
      size: 10,
      current: 1,
      showFrom: 0,
      dialogVisible: false,
      editForm: {},

      tableData: [],

      editFormRules: {
        question: [
          {required: true, message: '请输入标题', trigger: 'blur'}
        ],
        category: [
          {required: true, message: '请选择分类', trigger: 'blur'}
        ],
        answer: [
          {required: true, message: '请输入答案', trigger: 'blur'}
        ]
      },

      multipleSelection: [],

      permDialogVisible: false,
      permForm: {},
      permTreeData: [],

      editorOption: {
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'strike'],
              ['blockquote', 'code-block'],
              [{'header': 1}, {'header': 2}],
              [{'list': 'ordered'}, {'list': 'bullet'}],
              [{'script': 'sub'}, {'script': 'super'}],
              [{'indent': '-1'}, {'indent': '+1'}],
              [{'direction': 'rtl'}],
              [{'size': ['small', false, 'large', 'huge']}],
              [{'header': [1, 2, 3, 4, 5, 6, false]}],
              [{'font': []}],
              [{'color': []}, {'background': []}],
              [{'align': []}],
              ['clean'],
              ['link', 'image']
            ],
            handlers: {
              'image': function (value) {
                if (value) {
                  document.querySelector('.edit-uploader input').click()
                } else {
                  this.quill.format('image', false);
                }
                // this.$refs.aUpload.click() //自定义图片上传回调
              }
            }
          },
          syntax: {
            highlight: text => hljs.highlightAuto(text).value
          }
        }
      }
    }
  },
  created() {
    this.getQuestionList()

    this.$axios.get('/interview/category/list').then(res => {
      this.permTreeData = res.data.data
    })
  },
  methods: {
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      console.log("勾选")
      console.log(val)
      this.multipleSelection = val;

      this.delBtlStatu = val.length == 0
    },

    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.size = val
      this.getQuestionList()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.current = val
      this.getQuestionList()
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.dialogVisible = false
      this.editForm = {}
    },
    closeForm(formName) {
      this.$refs[formName].resetFields();
      this.dialogVisible = false
      this.showFrom = 0
    },
    handleClose() {
      this.resetForm('editForm')
    },

    getQuestionList() {
      this.$axios.get("/interview/question/list", {
        params: {
          keyword: this.searchForm.keyword,
          category: this.searchForm.category,
          current: this.current,
          size: this.size
        }
      }).then(res => {
        this.tableData = res.data.data.records
        this.size = res.data.data.size
        this.current = res.data.data.current
        this.total = res.data.data.total
      })
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios.post('/interview/question/' + (this.editForm.id ? 'update' : 'save'), this.editForm)
              .then(res => {

                this.$message({
                  showClose: true,
                  message: '恭喜你，操作成功',
                  type: 'success',
                  onClose: () => {
                    this.getQuestionList()
                  }
                });

                this.dialogVisible = false
                this.resetForm(formName)
              })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    editHandle(id) {
      this.$axios.get('/interview/question/info/' + id).then(res => {
        this.editForm = res.data.data

        this.dialogVisible = true
      })
    },
    delHandle(id) {

      var ids = []

      if (id) {
        ids.push(id)
      } else {
        this.multipleSelection.forEach(row => {
          ids.push(row.id)
        })
      }

      console.log(ids)

      this.$axios.post("/interview/question/delete", ids).then(res => {
        this.$message({
          showClose: true,
          message: '恭喜你，操作成功',
          type: 'success',
          onClose: () => {
            this.getQuestionList()
          }
        });
      })
    },
    permHandle(id) {
      this.permDialogVisible = true

      this.$axios.get("/interview/question/info/" + id).then(res => {

        // this.$refs.permTree.setCheckedKeys(res.data.data.menuIds)
        // this.permForm = res.data.data
        this.editForm = res.data.data
        this.showFrom = 1
        this.dialogVisible = true
      })
    },

    submitPermFormHandle(formName) {
      var menuIds = this.$refs.permTree.getCheckedKeys()

      console.log(menuIds)

      this.$axios.post('/interview/question/perm/' + this.permForm.id, menuIds).then(res => {
        this.$message({
          showClose: true,
          message: '恭喜你，操作成功',
          type: 'success',
          onClose: () => {
            this.getQuestionList()
          }
        });
        this.permDialogVisible = false
        this.resetForm(formName)
      })
    },

    //
    // 富文本编辑方法
    onEditorReady(editor) { // 准备编辑器
    },
    onEditorBlur() {
    }, // 失去焦点事件
    onEditorFocus() {
    }, // 获得焦点事件
    onEditorChange() {
    }, // 内容改变事件
    beforeEditorUpload() {
      // 显示loading动画
      this.quillUpdateImg = true
    },

    editorUploadSuccess(res, file) {
      // 获取富文本组件实例
      let quill = this.$refs.myQuillEditor.quill
      // 如果上传成功
      if (res.code === 0) {
        // 获取光标所在位置
        let length = quill.getSelection().index;
        // 插入图片  res.info为服务器返回的图片地址
        quill.insertEmbed(length, 'image', res.data.filepath)
        // 调整光标到最后
        quill.setSelection(length + 1)
      } else {
        this.$message.error('图片插入失败')
      }
      // loading动画消失
      this.quillUpdateImg = false
    },

    // 富文本图片上传失败
    editorUploadError() {
      // loading动画消失
      this.quillUpdateImg = false
      this.$message.error('图片插入失败')
    }
    //
  }
}
</script>

<style scoped>
.el-pagination {
  float: right;
  margin-top: 22px;
}
</style>
